import React from 'react';

import { useTranslation } from 'react-i18next';

function BannerCard(){
    const { t } = useTranslation();

    const cardData = [
        {title: t('home.seasonality-trends'), subtitle:'BTC', price:'16,048.40', percent:'-1.26'},
        {title: t('home.cot'), subtitle:'ETH', price:'1,122.44', percent:'-1.55'},
        {title: t('home.overvalued-undervalued'), subtitle:'USDT', price:'1.00', percent:'0.0099'},
    ];

    return(
        <>
            {cardData.map((data, index)=>(
                <div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.2s" key={index}>
                    <div className="icon-bx-wraper style-1 box-hover">
                        <div className="icon-media">
                            {/* <img src={data.image} alt="" /> */}
                            <div className="icon-info">
                                <h5 className="title" style={{color: '#7E87BF'}}>{data.title}</h5>
                                {/* <span>{data.subtitle}</span> */}
                            </div>
                        </div>
                        {/* <div className="icon-content">
                            <ul className="price">
                                <li>
                                    <h6 className="mb-0 amount">${data.price}</h6>
                                    <span className= {`percentage ${index===2 ? "text-green" : "text-red"}`}>{data.percent}%</span>
                                </li>
                                <li>
                                    <span>Latest price</span>
                                    <span>24h change</span>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            ))}
        </>
    )
}

export default BannerCard;