import React from 'react';
import { Link} from 'react-router-dom';

//images
import shape2 from './../assets/images/home-banner/shape2.png';
import bgimage from './../assets/images/background/bg1.jpg';
import logowhite from './../assets/images/logo-white.png';
import { useTranslation } from 'react-i18next';

function Footer(){
    const { t } = useTranslation();

    return(
        <>
            <footer className="site-footer style-1" id="footer">
                <img className="bg-shape1" src={shape2} alt="" />
                <div className="footer-top background-luminosity" style={{backgroundImage: "url("+ bgimage +")"}}>
                    <div className="container">
                        <div className="row" style={{justifyContent: 'space-between'}}>
                            <div className="col-xl-4 col-lg-4 col-md-12" >
                                <div className="widget widget_about">
                                    <div className="footer-logo logo-white">
                                        <Link to={"/"}><img src={logowhite} alt="" /></Link>
                                    </div>
                                    {/* <div className="dz-social-icon transparent space-10">
                                        <ul>
                                            <li><a target="_blank" className="fab fa-facebook-f" href="https://www.facebook.com/"></a></li>{" "}
                                            <li><a target="_blank" className="fab fa-instagram" href="https://www.instagram.com/"></a></li>{" "}
                                            <li><a target="_blank" className="fab fa-twitter" href="https://twitter.com/"></a></li>{" "}
                                            <li><a target="_blank" className="fab fa-youtube" href="https://youtube.com/"></a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12" >
                                <div className="widget widget_services">
                                    <h4 className="widget-title">{t('footer.other-links')}</h4>
                                    <ul>
                                        <li><Link to={"/about-us"}>{t('footer.policy-and-agreements')}</Link></li>
                                        {/* <li><Link to={"/pricing"}>Pricing Plan</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-xl-3 col-lg-4 col-sm-12" >
                                <div className="widget widget_locations">
                                    <h4 className="widget-title">Locations</h4>
                                    <div className="clearfix">
                                        <h6 className="title">Washington</h6>
                                        <p>1559 Alabama Ave SE, DC 20032, Washington, USA</p>
                                        <img src={flags} alt="" />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>
                {/* <div className="footer-bottom text-center">
                    <div className="container">
                        <span className="copyright-text">{t('footer.abf-programming')}</span>
                    </div>
                </div> */}
            </footer>
        </>
    )
}
export default Footer;