import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//   en: {
//     translation: {
//       "Welcome to React": "Login"
//     }
//   },
//   fr: {
//     translation: {
//       "Welcome to React": "Bienvenue à React et react-i18next"
//     }
//   }
// };

const i18nConfig = {
  debug: process.env.NODE_ENV !== 'production',
  defaultNS: 'translation',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  load: 'languageOnly',
  react: {
    useSuspense: true,
  },
  detection: {
    order: [
      'localStorage',
      'querystring',
      'cookie',
      'sessionStorage',
      'navigator',
      'htmlTag',
      'path',
      'subdomain',
    ],
  },
};

const availableLanguages = [
  { code: 'en', name: 'English' },
];
const availableLanguageCodes = availableLanguages.map((l) => l.code);

const getTranslates = (language) =>
  require(`./languages/${language}.translation.json`);

const resources = availableLanguageCodes.reduce(
  (acc, lang) => ({
    ...acc,
    [lang]: {
      [i18nConfig.defaultNS]: {
        ...getTranslates(lang),
      },
    },
  }),
  {}
);
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;