import React, {useEffect, useState} from 'react';
import {NavLink, Link} from 'react-router-dom';

import Logo from './../assets/images/logo.png';
import LogoWhite from './../assets/images/logo-white.png';
import { useTranslation } from 'react-i18next';

function Header(){

    /* for sticky header */
	const [headerFix, setheaderFix] = React.useState(false);
    const loginUrl = process.env.REACT_APP_SCREENER_LOGIN_URL;
    const registerUrl = process.env.REACT_APP_SCREENER_REGISTER_URL;
    const { t } = useTranslation();
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []);

    const [sidebarOpen, setSidebarOpen] = useState(false);	
	// console.log(loginUrl);
    return(
        <>
            <header className="site-header mo-left header header-transparent style-1">
                <div className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""}`}>
                    <div className="main-bar clearfix">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <a href={"#home"} className="logo-dark"><img src={Logo} alt="" /></a>
                                <a href={"#home"} className="logo-light"><img src={LogoWhite}  alt="" /></a>
                            </div>
                            
                            <button  type="button"
                                className={`navbar-toggler  navicon justify-content-end ${sidebarOpen ? 'open' : 'collapsed' }`} 
                                onClick={()=>setSidebarOpen(!sidebarOpen)}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>                            
                            <div className="extra-nav">
                                <div className="extra-cell">
                                    <a className="btn btn-outline-primary text-white" target="_blank" rel="noreferrer" href={loginUrl}>{t('header.login')}</a>
                                    <a className="btn btn-primary btn-gradient btn-shadow" target="_blank" rel="noreferrer" href={registerUrl}>{t('header.register')}</a>
                                </div>
                            </div>                           
                                
                            <div className={`header-nav navbar-collapse collapse justify-content-end ${sidebarOpen ? "show" : ""}`} id="navbarNavDropdown" >
                                <div className="logo-header mostion">
                                    <a href={"#home"} className="logo-dark"><img src={Logo} alt="" /></a>
                                </div>                            
                                <ul className="nav navbar-nav navbar">
                                    <li><a href={"#about-us"}>{t('header.about-us')}</a></li>
                                    <li><a href={"#contact"}>{t('header.contact')}</a></li>
                                </ul>                               
                            
                                
                            </div>
                            
                        </div>
                    </div>

                </div>
            </header>
        </>
    )
}
export default Header;