import axios from 'axios'

const server_url = process.env.REACT_APP_API_BASE_URL

export function sendData(recaptchaToken, firstName, lastName, email, phone, message) {
  //axios call
  const postData = {
    email,
    firstName,
    lastName,
    phone,
    message
  }
  return axios.post(`${server_url}/v1/email/message?g-recaptcha-response=${recaptchaToken}`, postData)
}